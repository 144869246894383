import React from 'react'
import Testinonials from './Testinonials'
import FreqAskedQue from './FreqAskedQue'
import Workplace from './Workplace'
import Posts from './Posts'

const Blogs = () => {
  return (
    <div>
        <Workplace/>
        <Posts/>
        <Testinonials/>
        <FreqAskedQue/>
        
    </div>
  )
}



export default Blogs