import React from 'react'
import Guide from './Guide'
import TimeOffer from './TimeOffer'
import FreqAskedQue from './FreqAskedQue'

const Courses = () => {
  return (
    <div>
        <Guide/>
        <TimeOffer/>
        <FreqAskedQue/>
    </div>
  )
}

export default Courses